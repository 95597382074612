
import { dispatchGetProductsList } from '@/store/products/actions';
import { readProductsList } from '@/store/products/getters';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})

export default class ProductsList extends Vue {
    public perPage = 10;
    public page = 1;
    public isFavorite = false;
    public packageSize = 1;
    public priceFilter = [1, 20];
    public productListType = 'grid';
    public loadingState: any = {
        page: true,
    };

    public async mounted() {
        // this.loadingState.list = true;
        const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;
        // await dispatchGetProductsList(this.$store, {rowQuery});
        // this.loadingState.list = false;
        this.loadingState.page = false;
    }

    public handleChangePage(page) {
        if (this.page != page) {
            this.page = page;
            const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;
            // dispatchGetProductsList(this.$store, {rowQuery});
        }
    }

    get productsResponse() {
        return readProductsList(this.$store);
    }
}
